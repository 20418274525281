<template>
  <div>
      <vue-header title="预约" rightName="预约记录" :rightFun="()=>
          this.$router.push({
                name:'record'
          })
      "/>
      <div class="sub-box" v-if="!this.$route.params.id||subscribe_info">
          <div class="sub-input">
              <div class="flex" v-if="last_info">
                  <div class="sub-title">
                      历史记录：
                 </div>
                 <div class="flex-1">
                     <div class="sub-record" @click="lastInfoFun">{{last_info.sys_organizations.name}}</div>
                 </div>
              </div>
              <div class="flex">
                  <div class="sub-title">
                      <span>*</span>选择服务点：
                 </div>
                 <div class="sub-subscribe flex-1" v-if="subscribe_info">
                      <div>{{subscribe_info.sys_organizations.name}}</div>
                  </div>
                  <div class="sub-date flex-1" @click="()=>this.service=true" v-else>
                      <div v-if="organizations[organizationsIndex] && !service">{{organizations[organizationsIndex].name}}</div>
                  </div>
              </div>
              <div class="address" v-if="organizations[organizationsIndex] && !service">
                  详细地址：{{organizations[organizationsIndex].address}}
              </div>
              <div class="address" v-if="subscribe_info">
                  详细地址：{{subscribe_info.sys_organizations.address}}
              </div>
          </div>
          <div class="sub-input">
              <div class="flex">
                  <div class="sub-title">
                      <span>*</span>预 约 日 期：
                  </div>
                  <div class="sub-subscribe flex-1" v-if="subscribe_info">
                      <div>{{subscribe_info.subscribe_date}}</div>
                  </div>
                  <div class="sub-date flex-1" @click="()=>this.birth=true" v-else>
                      <div v-if="!birth && date">
                            {{date}}
                      </div>
                  </div>
              </div>
          </div>
          <div class="sub-input" v-if="time_slot.length>0">
              <div :class="{ flex: subscribe_info?true:false }">
                <div class="sub-title">
                    <span>*</span>预约时间段：
                </div>
                <div class="sub-subscribe flex-1" v-if="subscribe_info">
                    <div>{{subscribe_info.subscribe_time}}</div>
                </div>
                <van-radio-group v-model="radio" v-else>
                    <van-radio v-for="(time,index) in time_slot" :key="index" :disabled="time.surplus_people==0" :name="time.subscribe_time">
                        <span :class="{ash:time.surplus_people==0}">{{time.subscribe_time}}</span>
                    </van-radio>
                </van-radio-group>
              </div>
          </div>
          <div class="sub-input flex" v-if="subscribe_info">
              <div class="flex flex-1">
                <div class="sub-title">
                    <span>*</span>预约时间段：
                </div>
                <div class="sub-subscribe flex-1">
                    <div>{{subscribe_info.subscribe_time}}</div>
                </div>
              </div>
          </div>
          <button class="sub-sumbit" @click="()=>cancelFun(subscribe_info.id)" v-if="subscribe_info && subscribe_info.status==1">取消预约</button>
          <button class="sub-sumbit cancel"  v-else-if="subscribe_info && subscribe_info.status==2">预约已取消</button>
          <button class="sub-sumbit" @click="subConfirm" v-else>确定</button>
      </div>
      <van-calendar v-model="birth" color="#22B171" @confirm="dateConfirm"/>
     <div class="picker-curtain" v-if="birth" @click="()=>this.birth=false"></div>
     <div class="picker-bottom" v-on:click.stop v-if="service">
            <div class="picker-bac flex">
                <div class="curtain-left" @click="()=>this.service=false">取消</div>
                <div class="flex-1 curtain-center">选择服务点</div>
                <div class="curtain-right" @click="()=>{
                    if(organizations.length>0 && !this.organizationsIndex){
                        this.organizationsIndex = 0;
                    }
                    timeSlotFun();
                    this.service=false;
                }">确定</div>
            </div>
            <div v-if="organizations.length>0">
                <van-picker
                    :columns="organizationsPicker"
                    @change="organizationsConfirm"
                    visible-item-count="9"
                />
            </div>
     </div>
     <div class="picker-curtain" v-if="service" @click="()=>this.service=false"></div>
  </div>
</template>
<script>
import vueHeader from '@/components/header';
import { titleFun } from '@/utils';
import { mapActions , mapState } from 'vuex';
import { Notify , Dialog } from 'vant';
export default {
  name: 'subscribe',
  components:{
    vueHeader
  },
  data(){ 
    return {
        birth:false,
        service:false,
        minDate:new Date(),
        currentDate:new Date(),
        organizationsIndex:false,
        date:false,
        radio:''
    }
  },
  created:function(){
      this.organizationsMain({});
      titleFun('预约');
      if(this.$route.params.id){
          this.subscribeInfoMain({id:this.$route.params.id})
      }else{
          this.lastInfoMain({});
      }
  },
  computed:{
      ...mapState('my',['organizations','organizationsPicker','last_info', 'subscribe_info','time_slot']),
  },
  methods:{
      organizationsConfirm(info,value,index){
          this.organizationsIndex = index;
      },

      dateConfirm(value){
          this.date = this.timeSubmit(value);
          this.birth = false;
      },
      
      timeSlotFun(){
          this.timeSlotMain({sys_organizations_id:this.organizations[this.organizationsIndex].id})
      },

      lastInfoFun(){
          this.timeSlotMain({sys_organizations_id:this.last_info.sys_organizations_id});
          this.organizations.map((item,index)=>{
              if(this.last_info.sys_organizations_id==item.id){
                  this.organizationsIndex=index;
              }
          });
          this.date = this.last_info.subscribe_date;
          var _this = this;
          setTimeout(function(){
              if(_this.time_slot.length && _this.lastInfoMap(_this.time_slot,_this.last_info.subscribe_time)!==0 ){
                  _this.radio = _this.last_info.subscribe_time;
              }
          },1000);
      },

      lastInfoMap(datas,timeName){
          let surplus_people = 0;
          datas.map((data)=>{
              if(data.subscribe_time==timeName){
                  surplus_people = data.surplus_people;
              }
          });
          return surplus_people;
      },
      
      subConfirm(){
          let bool = true
          if(!this.organizations[this.organizationsIndex]){
              bool=false;
              Notify({ type: 'danger', message: '请选择服务机构！' });
          }else if(!this.date){
              bool=false;
              Notify({ type: 'danger', message: '请选择服务日期！' });
          }else if(!this.radio){
              bool=false;
              Notify({ type: 'danger', message: '请选择服务时间段！' });
          }
          if(bool){
              this.addRecorMain({
                  data:{
                    subscribe_time:this.radio,
                    subscribe_date:this.date,
                    sys_organizations_id:this.organizations[this.organizationsIndex].id
                  },
                  _this:this   
              });
          }
      },

      cancelFun(id){
            Dialog.confirm({
            title: '取消预约',
            message:
                '您真的要取消预约吗？',
            })
            .then(() => {
                this.recordCancelMain({
                    id:id,
                    scene:'info'
                })
            })
      },
      
      timeSubmit(value){
        let date = new Date(value);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        return `${y}-${m}-${d}`;
      },
      ...mapActions('my',['organizationsMain','addRecorMain','lastInfoMain','subscribeInfoMain','recordCancelMain','timeSlotMain'])
  }
}
</script>

<style lang="scss" scoped>
  @import './subscribe';
</style>